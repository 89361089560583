<template>
  <v-dialog v-model="showModal" max-width="90%">

    <template v-slot:activator="{ attrs, on }">
      <slot v-bind="{on, attrs}" name="activator">
        <v-btn v-on="on" color="primary" small v-bind:attrs="attrs">Créer</v-btn>
      </slot>
    </template>

    <v-card>

        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon @click="reset">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

      <vue-scroll>
      <v-card-text style="max-height: 70vh">


        <v-text-field
            v-model="creatingMessage.title"
            :error-messages="errors.title"
            label="Titre de la configuration"
            @input="errors.title = creatingMessage.title.length > 0 ? '' : 'Ce champ doit être renseigné'"
        ></v-text-field>

        <div class="d-flex flex-row align-start justify-space-between">
          <div class="d-flex flex-column align-stretch justify-space-between" style="width: 48%">

            <div class="d-flex flex-row align-center justify-space-between">

              <v-menu absolute offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      :value="getStart"
                      label="Date de début"
                  >
                    <template v-slot:append>
                      <v-btn icon small @click="creatingMessage.start = null">
                        <v-icon small>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </template>

                <v-date-picker
                    v-model="creatingMessage.start"
                    :locale="$i18n.locale"
                    color="primary"
                ></v-date-picker>

              </v-menu>

              <v-menu absolute offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      :value="getEnd"
                      label="Date de fin"
                  >
                    <template v-slot:append>
                      <v-btn icon small @click="creatingMessage.end = null">
                        <v-icon small>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </template>

                <v-date-picker
                    v-model="creatingMessage.end"
                    :locale="$i18n.locale"
                    color="primary"
                ></v-date-picker>

              </v-menu>

            </div>

            Couleur :
            <color-picker v-model="creatingMessage.color"></color-picker>

            Icon :
            <icon-picker v-model="creatingMessage.icon" :color="creatingMessage.color"></icon-picker>
            <v-textarea v-model="creatingMessage.message" :counter="maxTextLength" :error-messages="errors.text" placeholder="Votre message ici" @input="errors.text = creatingMessage.message.length > 0 ? '' : 'Ce champ doit être renseigné'" @keyup="checkMessage"></v-textarea>

            <h3>Aperçu</h3>
            <hr class="mb-2">
            <div :style="{maxHeight: '330px'}" class="homepage-message-preview d-flex flex-column align-center pa-8">
              <vue-scroll>
                <login-form :homepageMessage="creatingMessage"></login-form>
              </vue-scroll>

            </div>

          </div>

          <v-divider vertical></v-divider>

          <div class="d-flex flex-column align-stretch justify-space-between" style="width: 48%">

            <client-picker v-model="creatingMessage.list"></client-picker>

          </div>

        </div>
      </v-card-text>
      </vue-scroll>


      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="reset">Annuler</v-btn>
        <v-btn :color="actionColor ? actionColor : 'success'" @click="save">{{ actionLabel || "Créer"}}</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
import moment from "moment"
import ColorUtils from "@/Utils/Colors"

export default {
  name: "CreateForm",
  components: {
    IconPicker: () => import("@/Components/Commons/Inputs/Icon/IconPicker"),
    ColorPicker: () => import("@/Components/Commons/Inputs/Color/ColorPicker"),
    ClientPicker: () => import("@/Components/Commons/Inputs/Client/ClientPicker"),
    LoginForm: () => import("@/Components/Views/Login/LoginForm")
  },
  props: {
    value: [Array, Object],
    actionLabel: String,
    actionColor: String,
    title: String
  },
  data() {
    return {
      moment,
      ColorUtils,
      showModal: false,
      maxTextLength: 255,
      creatingMessage: {
        title: "",
        list: [],
        active: false,
        message: "",
        color: null,
        icon: null,
        start: null,
        end: null
      },
      errors: {
        title: "",
        text: ""
      }
    }
  },
  computed: {
    getStart() {
      return this.creatingMessage.start
          ? moment(this.creatingMessage.start, 'YYYY-MM-DD').format(this.getLocaleFormat(this.$i18n.locale))
          : ''
    },
    getEnd() {
      return this.creatingMessage.end
          ? moment(this.creatingMessage.end, 'YYYY-MM-DD').format(this.getLocaleFormat(this.$i18n.locale))
          : ''
    }
  },
  methods: {
    checkMessage() {
      this.creatingMessage.message = this.creatingMessage.message.substr(0, this.maxTextLength)
    },
    reset() {
      this.showModal = false

      this.creatingMessage = {
        title: "",
        list: [],
        active: false,
        message: "",
        color: null,
        icon: null,
        start: null,
        end: null
      }
      this.errors.title = ""
      this.errors.text = ""
    },
    save() {
      let error = false
      if (!this.creatingMessage.title) {
        this.errors.title = "Ce champ doit être renseigné"
        error = true
      }
      if (!this.creatingMessage.message) {
        this.errors.text = "Ce champ doit être renseigné"
        error = true
      }
      if (!error) {
        this.$emit('input', this.creatingMessage)
        this.$emit('save', this.creatingMessage)
        this.reset()
      }
    },
  },
  watch: {
    showModal(nValue) {
      if (nValue)
        this.creatingMessage = this.value
    }
  }
}
</script>

<style scoped>

.homepage-message-preview {
  background: rgb(239,239,239);
  background: linear-gradient(180deg, rgba(239,239,239,1) 0%, rgba(215,229,238,1) 100%);
  opacity: 0.95;
  width: 100%;
  height: 60vh;
  z-index: 2000;
}

</style>